import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Line } from "../components/elements"
import idea from "../images/illustrators/idea.svg"
import {
  CatchyHeadings,
  SubTitle,
  SmallParagraphs,
  Caption,
} from "../components/typography"

import { Button, AboutCard } from "../components/elements"
import { HeaderPage, WhyChooseUs } from "../components/structure"

import perfection from "../images/about/perfection.svg"
import principle from "../images/about/principle.svg"
import peoplefirst from "../images/about/peoplefirst.svg"

import whoweare from "../images/stats/whoweare.svg"

export function OurGoal() {
  return (
    <>
      <Details>
        <Caption>The three values that sum up our business are:</Caption>
      </Details>
      <BottomSection>
        <AboutCard
          textalign="left"
          src={perfection}
          title="Perfection"
          detail="We strive to achieve premium results in everything we do, always going above and beyond to deliver for our clients."
          detail1="We carefully research and implement the best strategies across all stages of the journey, from building brand awareness to providing SEO services to connecting with customers. Using tried and tested techniques, we drive growth in your business as if it were our own. "
        />
        <AboutCard
          textalign="left"
          src={peoplefirst}
          title="People First"
          detail="We may operate in a digital space, but we always remember that we’re dealing with real people. Customer satisfaction is our top priority."
          detail1="Above all else, we always remember that our clients are real people who happen to need SEO services. That’s why customer satisfaction is our top priority. You can rely on us to support you whenever you need it with a service that is friendly and personable."
        />
        <AboutCard
          textalign="left"
          src={principle}
          title="Principle"
          detail="We always uphold our integrity and strong moral principles. Our business succeeds through hard work and dedication."
          detail1="Our clean and fair strategies deliver the results we promise and we always act in your best interest. We pride ourselves on being transparent with our customers and are committed to providing a service that is honest."
        />
      </BottomSection>
      <ButtonCover>
        <Button to="/contact/">Contact us now</Button>
      </ButtonCover>
    </>
  )
}

export default function About() {
  return (
    <Layout>
      <SEO title="About us" />
      <HeaderPage
        src={whoweare}
        color={props => props.theme.color.complimentry}
        bgColor={props => props.theme.color.bgFirst}
        title="Please read our values and process of work"
        catchy="About us"
      />
      <Line />
      <Section>
        <TopSection>
          <Illustrator src={idea} alt="who we are" />
          <Content>
            <CatchyHeadings>Our History.</CatchyHeadings>
            <SubTitle>Who we are</SubTitle>
            <SmallParagraphs>
              With offices in both Melbourne and Sydney, eDigitalHub is a
              premium digital marketing agency. We are a team of experts with a
              passion for assisting our clients in achieving their biggest
              business goals, from increasing website traffic to generating
              sales revenue through SEO services. Our goal is to empower our
              customers through the most effective digital marketing strategies.
            </SmallParagraphs>
          </Content>
        </TopSection>
        <OurGoal />
      </Section>
      <Line />
      <WhyChooseUs
        smallTitle="We are a full-service business providing everything you need to drive sales and grow your company, from website design to digital marketing to SEO services. Our agency customises our strategies for each and every client, boasts a team of qualified experts, and has a proven track record of success. After several years in the industry, you can trust that we know what we’re doing."
        detail1="When we’re creating a website or digital marketing strategy for your business, you are our focus. Our customised approach means that our techniques revolve around what works for you. Utilising thorough research and analysis, we carefully tailor our strategies so they fit your individual needs, as well as the objectives and strengths of your business.
        What works for one company might not work for another. We always take into account your industry, your goals, and your current situation to come up with the best way to showcase your brand, connect with your tribe, and expand your business."
        detail2="We have several years of experience in digital marketing. Our passionate and professional team members are experts in all parts of the process, from keyword research to SEO services.
        Through trial and error, we have now perfected our techniques and strategies. We are confident in identifying the best approach to take with each individual business."
        detail3="In the past, our methods have helped many businesses to grow and thrive in the digital age. We have worked with everyone from brand-new startups to Fortune 500 companies, identifying the specific method that will work for each customer.
        Over the years, our strategies have helped businesses to:

	      1.	Increase brand identity, visibility, and recognition
      	2.	Drive website traffic and grow customer base
        3.	Create thriving online stores
        4.  Generate revenue through increased sales
        "
      />
    </Layout>
  )
}

const Section = styled.section`
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 5rem 0;
  background-color: ${props => props.theme.color.light};
  /* border-bottom-width: 0.6px;
  border-bottom-style: solid;
  border-bottom-color: ${props =>
    props.theme.color
      .catchy};
  border-top-width: 0.6px;
  border-top-style: solid;
  border-top-color: ${props =>
    props.theme.color.catchy}; */
`

const TopSection = styled.div`
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  @media only screen and (max-width: 75em) {
    width: 99%;
    justify-content: space-between;
  }
  @media only screen and (max-width: 56.25em) {
    width: 99%;
    justify-content: space-between;
  }
  @media only screen and (max-width: 37.5em) {
    width: 90%;
  }
`

const Details = styled.div`
  width: 90%;
  margin: 0 auto;
  text-align: center;
  padding: 5rem 0;
`

const BottomSection = styled.div`
  width: 75%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  @media only screen and (max-width: 75em) {
    width: 100%;
  }
  @media only screen and (max-width: 56.25em) {
    width: 100%;
  }
  @media only screen and (max-width: 37.5em) {
    width: 97%;
  }
`

const Illustrator = styled.img`
  width: 60rem;
  height: auto;
  @media only screen and (max-width: 75em) {
    width: 45rem;
  }
  @media only screen and (max-width: 56.25em) {
    width: 45rem;
  }
  @media only screen and (max-width: 37.5em) {
    width: 35rem;
    margin: 0 auto;
    order: 1;
  }
  @media only screen and (min-width: 112.5em) {
    width: 85rem;
  }
`

const Content = styled.div`
  width: 45%;
  padding: 5rem 0 0 0;
  /* flex: 1; */
  justify-self: center;
  @media only screen and (max-width: 75em) {
    /* width: auto; */
    padding: 5rem 3rem 0 3rem;
  }
  @media only screen and (max-width: 56.25em) {
    /* width: auto; */
    padding: 5rem 1rem 0 3rem;
    text-align: center;
  }
  @media only screen and (max-width: 37.5em) {
    width: auto;
    padding: 5rem 0 0 0;
  }
`
const ButtonCover = styled.div`
  text-align: center;
  padding: 5rem 0;
`
